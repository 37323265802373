<template>
    <v-container fluid :style="{'background-color':$store.getters.getColorPalette().backgroundColorName,'color':$store.getters.getColorPalette().accentCode}">
      <v-row no-gutters>
                <v-col>
                    <DeviceNumberPanel/>
                </v-col>
            </v-row> 
      <v-row no-gutters>
                <v-col>
                    <v-sheet>
    <v-tabs
      :dark="$store.getters.getColorPalette().isDark"
      :background-color="$store.getters.getColorPalette().tab"
      show-arrows
      v-model="tab"
      style="margin-bottom:2px"
    >
      <v-tabs-slider :color="$store.getters.getColorPalette().inputtext2Color"></v-tabs-slider>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/engineeringPanel/machineParams'">
      <v-tab
    :dark="$store.getters.getColorPalette().isDark"
      >
        Param Machine View
      </v-tab>
      </router-link>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/engineeringPanel/modbusParams'">
      <v-tab
    :dark="$store.getters.getColorPalette().isDark"
      >
        Modbus Params
      </v-tab>
      </router-link>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/engineeringPanel/paramCounts'">
      <v-tab
   :dark="$store.getters.getColorPalette().isDark"
      >
        Param counts
      </v-tab>
      </router-link>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/engineeringPanel/APIDataCounts'">
      <v-tab
   :dark="$store.getters.getColorPalette().isDark"
      >
       Api Data Counts
      </v-tab>
      </router-link>

      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/engineeringPanel/worspaceMachineParams'">
      <v-tab
      :dark="$store.getters.getColorPalette().isDark"
      >
      Workspace Machine View
      </v-tab>
      </router-link>
    </v-tabs>
    </v-sheet>
    <router-view></router-view>
                    
                </v-col>
            </v-row>
            
            <!-- <v-row no-gutters>
              <v-col cols="12">
                <v-tabs-items v-model="tab" dark   :color="$store.getters.getColorPalette().backgroundColorCode">
      <v-tab-item
      :dark="$store.getters.getColorPalette().isDark"
      >
      
      <MachineParamViewTable/>
      </v-tab-item>
      <v-tab-item
      :dark="$store.getters.getColorPalette().isDark"
      >
      
      <ModbusParamViewTable/>
      </v-tab-item>
      <v-tab-item
      :dark="$store.getters.getColorPalette().isDark"
      >
      
      <ParamCountTable/>
      </v-tab-item>
      <v-tab-item
      :dark="$store.getters.getColorPalette().isDark"
      >
      
      <APIDataCountTableView/>
      </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row> -->
            
    </v-container>
</template>
<script lang="js">
import DeviceNumberPanel from '@/components/panels/DeviceNumberPanel'
// import MachineParamViewTable from '@/components/tables/MachineParamViewTable.vue';
// import ModbusParamViewTable from '@/components/tables/ModbusParamViewTable.vue';
// import ParamCountTable from '@/components/tables/ParamCountTable.vue';
// import APIDataCountTableView from '@/components/tables/APIDataCountTableView.vue';
export default {
 name:'EngineeringPanel',
 components:{
  DeviceNumberPanel
  // MachineParamViewTable,
  // ModbusParamViewTable,
  // ParamCountTable,
  // APIDataCountTableView,
 },
 mounted() {

   document.title='Engineering Panel'
        if (!this.$store.state.loggedIn) {
     this.$router.push("/");
   }else{
      this.$router.push('/engineeringPanel/machineParams');
    }
   },
 data(){
   return {
     tab:null,
     info:'',
     showDismissibleAlert:false,
     loading:false

   }
 }
}
</script>